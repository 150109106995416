import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { IWorkflowExplanation } from '@tymely/atoms';
import { TimelineList } from '@tymely/components';
import { useDecisionQuery } from '@tymely/services';

type WorkflowExplanationTimelineProps = { expl: IWorkflowExplanation };

const _WorkflowExplanationTimeline = styled(Box)`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing(1, 1, 1, 0)};
    border-radius: 0.2em;
    cursor: pointer;
`;

const _Label = styled(Typography, { shouldForwardProp: (prop: string) => !['didHit'].includes(prop) })<{
    didHit: boolean;
}>`
    padding: ${({ theme }) => theme.spacing(1)};
    text-align: center;
    margin-right: ${({ theme }) => theme.spacing(1)};
    border-radius: 0.2em;
    background-color: ${({ didHit }) => (didHit ? '#91B850' : '#D87E5F')};
    line-height: 1rem;
    height: 2rem;
    min-width: 6rem;
    align-self: flex-start;
`;

const _LabelContainer = styled(Box)`
    display: flex;
`;

export const WorkflowExplanationTimeline = React.memo(({ expl }: WorkflowExplanationTimelineProps) => {
    const [open, setOpen] = React.useState(false);

    return (
        <_WorkflowExplanationTimeline>
            <_LabelContainer onClick={() => setOpen(!open)}>
                <_Label didHit={expl.did_hit} variant="subtitle2">
                    {expl.wf_id}
                </_Label>
                {expl.wf_title || ''}
            </_LabelContainer>
            {open && <TimelineList id="comment-actions" items={expl.condition_explanations} />}
        </_WorkflowExplanationTimeline>
    );
});

WorkflowExplanationTimeline.displayName = 'WorkflowExplanationTimeline';

export const PolicyExplanation = () => {
    const decisionQuery = useDecisionQuery();
    const wfExplanations = decisionQuery.data?.wf_explanations;

    return (
        <Box>
            {wfExplanations
                ?.slice()
                .reverse()
                .map((expl, at) => (
                    <WorkflowExplanationTimeline key={at /*`expl-item-wf-${expl.wf_id}`*/} expl={expl} />
                ))}
        </Box>
    );
};
